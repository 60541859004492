// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  awsconfig: {
    aws_appsync_authenticationType: 'AWS_IAM',
    aws_appsync_graphqlEndpoint: 'https://uk2fnrpiknbwff6uqfxsgkbg2a.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: "us-east-1:45e6f3c2-9928-42e2-a216-c717fedc6698",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_BwpL2bhUI",
    aws_user_pools_web_client_id: "37j4fv81umt8g853juhft7jmf4",
    oauth: {
        domain: "g2-bleung.auth.us-east-1.amazoncognito.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "https://localhost:4200/",
        redirectSignOut: "https://localhost:4200/",
        responseType: "code"
    },
    federationTarget: "COGNITO_USER_POOLS",
    image_path_url: "assets-bleung.anguslive.com",
    buyer_portal_path_url: "app-bleung.anguslive.com"
  },
  salemanagementcontactdefault: {
    name: "Jeremy Haag",
    email: "jeremy@anguslive.com",
    phone: "(406) 833-0117"
  }
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
